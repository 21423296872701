html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #7405CC 0vh, #C205FF 100vh);
  height: 100%;
  overflow: auto;
  scroll-behavior: auto;
  overflow-x: hidden;
}
