#toggle {
  display: none;
}

.hamburger {
  z-index: 100;
  position: absolute;
  top: 10px;
  right: 18px;
}

.hamburger div {
  position: relative;
  width: 35px;
  height: 3px;
  background-color: white;
  margin-top: 9px;
  transition: all 0.3s ease-in-out;
}

.nav {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #7405CC;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  z-index: 50;
}
.nav-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  z-index: 50;
}

/**
Animations
**/
#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -4px;
}

#toggle:checked + .hamburger + .nav {
  top: 0;
  transform: scale(1);
}
